$(function () {
    if ($('.modal-send').length === 0) return ;

    let data = {};
    setInterval(function () {
        data.name = $('[name="modal-name"]').val();
        data.phone = $('[name="modal-phone"]').val();
        data['g-recaptcha-response'] = (grecaptcha && grecaptcha.getResponse) ? grecaptcha.getResponse() : "";

        if (data.name.length > 2 && data.phone.length > 5 && data['g-recaptcha-response'] !== "" && $('[name="accept"]').is(":checked")) {
            $('.modal-send').removeClass('disabled');
        } else {
            $('.modal-send').addClass('disabled');
        }
    }, 50);

    $(document).on('click', '.modal-send:not(.disabled)', function () {
        $.post('', data, function () {
            $('.form-result').show();
            $('.form-content').hide();
        });
    });

    $(document).on('click', '[data-modal]', function () {
        $('body').addClass('modal-active');
        setTimeout(function () {
            $('body').addClass('modal-show');
        }, 20);
    });

    $(document).on('click', '.close-modal-action', function () {
        $('body').removeClass('modal-show');
        setTimeout(function () {
            $('body').removeClass('modal-active');
        }, 350);
    });
});