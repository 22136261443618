import sizes from "../scss/_settings/_settings.scss";

window.sizes = {};
Object.keys(sizes).map(function(key){
    window.sizes[key] = parseInt(sizes[key]);
});

require('./parts/modal.js');
require('./parts/product-zoom.js');
require('./parts/application.js');

require('../scss/app.scss');
