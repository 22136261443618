$(function () {
    $('.menu-nav-item[href="'+location.pathname+'"]').addClass('current');

    (function () {
        let form = $('form.contact-form');
        if (form.length == 0) return;

        setInterval(function () {
            let captcha = (grecaptcha && grecaptcha.getResponse) ? grecaptcha.getResponse() : "";
            if (captcha && $('[name="accept"]').is(":checked")) {
                $('.form-send').removeClass('disabled').attr('disabled', false);
            } else {
                $('.form-send').addClass('disabled').attr('disabled', true);
            }
        }, 50);
    })();


    $(document).on('click', '[data-mobile-opener]', function () {
        $('[data-menu]').toggleClass('opened');
    });



});