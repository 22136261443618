
let cnt = $('[data-hover-zoom]');
let area = $('.large-version');
let image = $('.large-version .img');
let visible = false;
let remover = null;

$('[data-thumb]:first').addClass('current');

$(document).on('click', '[data-thumb]', function () {
    $('[data-thumb-src]').attr('src', this.dataset['thumb']);
    $('[data-thumb]').removeClass('current');
    $(this).addClass('current');
});

$(document).on('mousemove', '[data-hover-zoom]', function (e) {
    let x = e.pageX - cnt.offset().left,
        y = e.pageY - cnt.offset().top;

    if (!visible) {
        clearTimeout(remover);
        visible = true;
        cnt.addClass('active');
        cnt.removeClass('overflow');
        image.css({ width: cnt.width(), height: cnt.height() });
    }

    if (x > cnt.width() - 30 || x < 30 || y > cnt.height() - 30 || y < 30) {
        visible = false;
        cnt.removeClass('active');
        remover = setTimeout(function () {
            cnt.addClass('overflow');
        }, 181);
    }

    area.css({ left: x, top: y });
    image.css({ left: - x + 60, top:  - y + 60 });
});
